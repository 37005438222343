import Vue from "vue";
import Vuex from "vuex";
import sliders from "./sliders";
import translations from "./translations";
import excel from "./excel";
import user from "./user";
import youtube from "./youtube";

Vue.use(Vuex);
const state = {
  isTranslating: false,
};

const getters = {
  isTranslating: (state) => state.isTranslating,
};

const mutations = {
  setTranslate(state, payload) {
    state.isTranslating = payload;
  },
};
const store = new Vuex.Store({
  state,
  getters,
  mutations,
  modules: {
    sliders: sliders,
    translations: translations,
    excel: excel,
    user: user,
    youtube: youtube,
  },
});
export default store;
