import instance from "../helpers/server";
import { api } from "../constant/index";
const state = () => ({
  topLogo: "",
  sliders: [],
  introduceLogo: "",
  talkshows: [],
  topic1Image: [],
  topic2Image: [],
  topic3Image: [],
});
const getters = {
  topLogo: (state) => state.topLogo || "",
  sliders: (state) => state.sliders || [],
  introduceLogo: (state) => state.introduceLogo || "",
  talkshows: (state) => state.talkshows || [],
  topic1Image: (state) => state.topic1Image || [],
  topic2Image: (state) => state.topic2Image || [],
  topic3Image: (state) => state.topic3Image || [],
};
const mutations = {
  setTopLogo(state, payload) {
    state.topLogo = payload;
  },
  setSliders(state, payload) {
    state.sliders = payload;
  },
  setIntroduceLogo(state, payload) {
    state.introduceLogo = payload;
  },
  setTalkshows(state, payload) {
    state.talkshows = payload;
  },
  setTopic1(state, payload) {
    state.topic1Image = payload;
  },
  setTopic2(state, payload) {
    state.topic2Image = payload;
  },
  setTopic3(state, payload) {
    state.topic3Image = payload;
  },
};
const actions = {
  async getTopLogo({ commit }) {
    const res = await instance.get(api.topLogo);
    commit("setTopLogo", res?.data);
    return res?.data;
  },
  async onSaveLogo({ commit }, { file, name, location }) {
    if (!!file && typeof file == "object") {
      const formData = new FormData();

      formData.append("file", file);
      formData.append("fileName", name);
      formData.append("location", location);
      return await instance.post(api.upload, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    }
  },
  async getSliders({ commit }, payload) {
    const res = await instance.get(api.sliders, {
      params: payload,
    });
    commit("setSliders", res?.data);
    return res?.data;
  },

  async getTalkShow({ commit }, payload) {
    const res = await instance.get(api.talkshows, {
      params: payload,
    });
    commit("setTalkshows", res?.data);
    return res?.data;
  },

  async getIntroduceLogo({ commit }) {
    const res = await instance.get(api.introduce);
    commit("setIntroduceLogo", res?.data);
    return res?.data;
  },

  async getTopic1({ commit }, payload) {
    const res = await instance.get(api.topic1, {
      params: payload,
    });
    commit("setTopic1", res?.data);
    return res?.data;
  },

  async getTopic2({ commit }, payload) {
    const res = await instance.get(api.topic2, {
      params: payload,
    });
    commit("setTopic2", res?.data);
    return res?.data;
  },
  async getTopic3({ commit }, payload) {
    const res = await instance.get(api.topic2, {
      params: payload,
    });
    commit("setTopic3", res?.data);
    return res?.data;
  },

  async deleteImage({ commit }, payload) {
    return await instance.patch(api.deleteImage, payload);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
