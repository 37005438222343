import instance from "../helpers/server";
import { api, translationType } from "../constant/index";

const state = () => ({
  introTranslations: [],
  discussTranslations: [],
  contactTranslations: [],
  topic1Translation: [],
  topic2Translation: [],
  topic3Translation: [],
});
const getters = {
  introTranslations: (state) => state.introTranslations || [],
  discussTranslations: (state) => state.discussTranslations || [],
  contactTranslations: (state) => state.contactTranslations || [],
  topic1Translation: (state) => state.topic1Translation || [],
  topic2Translation: (state) => state.topic2Translation || [],
  topic3Translation: (state) => state.topic3Translation || [],
};
const mutations = {
  setTranslationType(state, { data, type }) {
    switch (type) {
      case translationType.introduction: {
        state.introTranslations = data;
        break;
      }
      default:
        break;
    }
  },
  setTranslations(state, payload) {
    state.introTranslations = payload.filter((i) => {
      return i.type == translationType.introduction;
    });
    state.discussTranslations = payload.filter(
      (i) => i.type == translationType.discussion
    );

    state.contactTranslations = payload.filter(
      (i) => i.type == translationType.contact
    );
    state.topic1Translation = payload.filter(
      (i) => i.type == translationType.topic1
    );
    state.topic2Translation = payload.filter(
      (i) => i.type == translationType.topic2
    );
    state.topic3Translation = payload.filter(
      (i) => i.type == translationType.topic3
    );
  },
};
const actions = {
  async getTranslationByType({ commit }, { language, type }) {
    const res = await instance.get(api.translationType, {
      params: {
        language,
        type,
      },
    });
    return res?.data;
  },
  async getTranslationsByLanguage({ commit }, language) {
    const res = await instance.get(api.translationLanguage, {
      params: {
        language,
      },
    });

    commit("setTranslations", res?.data);
    return res?.data;
  },

  async updateTranslation({ commit }, payload) {
    return await instance.patch(`${api.translation}`, payload);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
