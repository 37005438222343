import axios from "axios";

const instance = axios.create({
  //baseURL: `http://localhost:4000/`,
  baseURL: "https://api.kustohome.com.vn/",
  //baseURL: "https://api.alevanaturals.vn/",
  headers: {
    // Authorization: "Bearer {token}",
  },
});

instance.interceptors.request.use(function(request) {
  request.headers.common["Accept"] = "application/json";
  request.headers.common["Content-Type"] = "application/json";
  request.headers.common["Access-Control-Allow-Origin"] = true;
  return request;
});

export default instance;
