import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store/store";
import axios from "axios";

import { BootstrapVue } from "bootstrap-vue";
import VueYoutube from "vue-youtube";

import Scrollspy from "vue2-scrollspy";
var VueScrollTo = require("vue-scrollto");

Vue.config.productionTip = false;
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";
library.add(fas);
Vue.component("font-awesome-icon", FontAwesomeIcon);

import "@/assets/scss/style.scss";
import "@/assets/css/materialdesignicons.min.css";

Vue.use(VueYoutube);

Vue.use(Scrollspy);
Vue.use(VueScrollTo);

// Install BootstrapVue
Vue.use(BootstrapVue);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
