import instance from "../helpers/server";
import { api } from "../constant/index";
const state = () => {};
const getters = {};
const mutations = {};
const actions = {
  async doLogin({ commit }, payload) {
    const res = await instance.post(api.login, payload);
    return res?.data || {};
  },
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
