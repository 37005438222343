export const api = {
  upload: "upload",
  topLogo: "upload/get-logo",
  sliders: "upload/get-sliders",
  talkshows: "upload/get-talkshows",
  introduce: "upload/get-introduce",
  deleteImage: "upload/delete-image",
  translation: "translation",
  translationType: "translation/translation-type",
  translationLanguage: "translation/translation-lang",
  shareMessage: "excel",
  downloadExcel: "excel/download",
  login: "user/login",
  talkShowYoutube: "youtube",
  topic1: "upload/get-topic1",
  topic2: "upload/get-topic2",
};

export const filename = {
  topLogo: "top-logo",
  slider1: "slider1",
  slider2: "slider2",
  slider3: "slider3",
  slider4: "slider4",
  slider5: "slider5",
  introduceLogo: "introduce",
  talkshow1: "talkShow1",
  talkshow2: "talkShow2",
  talkshow3: "talkShow3",
  talkshow4: "talkShow4",
  talkshow5: "talkShow5",
  topic1Slider1: "topic1Slider1",
  topic1Slider2: "topic1Slider2",
  topic1Slider3: "topic1Slider3",
  topic1Slider4: "topic1Slider4",
  topic1Slider5: "topic1Slider5",
  topic2Slider1: "topic2Slider1",
  topic2Slider2: "topic2Slider2",
  topic2Slider3: "topic2Slider3",
  topic2Slider4: "topic2Slider4",
  topic2Slider5: "topic2Slider5",
  topic3Slider1: "topic3Slider1",
  topic3Slider2: "topic3Slider2",
  topic3Slider3: "topic3Slider3",
  topic3Slider4: "topic3Slider4",
  topic3Slider5: "topic3Slider5",
};

export const youtubeLink = {
  talkshowLink1: "talkshowLink1",
  talkshowLink2: "talkshowLink2",
  talkshowLink3: "talkshowLink3",
  talkshowLink4: "talkshowLink4",
  talkshowLink5: "talkshowLink5",
  talkshowTeaser1: "talkshowTeaser1",
  talkshowTeaser2: "talkshowTeaser2",
  talkshowTeaser3: "talkshowTeaser3",
  talkshowTeaser4: "talkshowTeaser4",
  talkshowTeaser5: "talkshowTeaser5",
  topic1watch1: "topic1watch1",
  topic1watch2: "topic1watch2",
  topic1watch3: "topic1watch3",
  topic1watch4: "topic1watch4",
  topic1watch5: "topic1watch5",
  topic2watch1: "topic2watch1",
  topic2watch2: "topic2watch2",
  topic2watch3: "topic2watch3",
  topic2watch4: "topic2watch4",
  topic2watch5: "topic2watch5",
  topic3watch1: "topic3watch1",
  topic3watch2: "topic3watch2",
  topic3watch3: "topic3watch3",
  topic3watch4: "topic3watch4",
  topic3watch5: "topic3watch5",
};

export const location = {
  header: "header",
  sliders: "sliders",
  introduce: "introduce",
  talkshows: "talkshows",
  topic1: "topic1",
  topic2: "topic2",
};

export const translationKeys = {
  introductions: {
    introduction_title: "introduction_title",
    introduction_subtitle: "introduction_subtitle",
    introduction_content: "introduction_content",
    introduction_criteria_1_key: "introduction_criteria_1_key",
    introduction_criteria_1_content: "introduction_criteria_1_content",
    introduction_criteria_2_key: "introduction_criteria_2_key",
    introduction_criteria_2_content: "introduction_criteria_2_content",
    introduction_criteria_3_key: "introduction_criteria_3_key",
    introduction_criteria_3_content: "introduction_criteria_3_content",
    introduction_criteria_4_key: "introduction_criteria_4_key",
    introduction_criteria_4_content: "introduction_criteria_4_content",
    introduction_criteria_5_key: "introduction_criteria_5_key",
    introduction_criteria_5_content: "introduction_criteria_5_content",
  },
  discussion: {
    discussion_title: "discussion_title",
    discussion_subtitle: "discussion_subtitle",
    discussion_talkshow_1_title: "discussion_talkshow_1_title",
    discussion_talkshow_1_subtitle: "discussion_talkshow_1_subtitle",
    discussion_talkshow_1_description: "discussion_talkshow_1_description",
    discussion_talkshow_1_customer: "discussion_talkshow_1_customer",
    discussion_talkshow_1_customer_name: "discussion_talkshow_1_customer_name",
    discussion_talkshow_1_watch_now: "discussion_talkshow_1_watch_now",
    discussion_talkshow_1_watch_teaser: "discussion_talkshow_1_watch_teaser",
    discussion_talkshow_2_title: "discussion_talkshow_2_title",
    discussion_talkshow_2_subtitle: "discussion_talkshow_2_subtitle",
    discussion_talkshow_2_description: "discussion_talkshow_2_description",
    discussion_talkshow_2_customer: "discussion_talkshow_2_customer",
    discussion_talkshow_2_customer_name: "discussion_talkshow_2_customer_name",
    discussion_talkshow_2_watch_now: "discussion_talkshow_2_watch_now",
    discussion_talkshow_2_watch_teaser: "discussion_talkshow_2_watch_teaser",
    discussion_talkshow_3_title: "discussion_talkshow_3_title",
    discussion_talkshow_3_subtitle: "discussion_talkshow_3_subtitle",
    discussion_talkshow_3_description: "discussion_talkshow_3_description",
    discussion_talkshow_3_customer: "discussion_talkshow_3_customer",
    discussion_talkshow_3_customer_name: "discussion_talkshow_3_customer_name",
    discussion_talkshow_3_watch_now: "discussion_talkshow_3_watch_now",
    discussion_talkshow_3_watch_teaser: "discussion_talkshow_3_watch_teaser",
    discussion_talkshow_4_title: "discussion_talkshow_4_title",
    discussion_talkshow_4_subtitle: "discussion_talkshow_4_subtitle",
    discussion_talkshow_4_description: "discussion_talkshow_4_description",
    discussion_talkshow_4_customer: "discussion_talkshow_4_customer",
    discussion_talkshow_4_customer_name: "discussion_talkshow_4_customer_name",
    discussion_talkshow_4_watch_now: "discussion_talkshow_4_watch_now",
    discussion_talkshow_4_watch_teaser: "discussion_talkshow_4_watch_teaser",
    discussion_talkshow_5_title: "discussion_talkshow_5_title",
    discussion_talkshow_5_subtitle: "discussion_talkshow_5_subtitle",
    discussion_talkshow_5_description: "discussion_talkshow_5_description",
    discussion_talkshow_5_customer: "discussion_talkshow_5_customer",
    discussion_talkshow_5_customer_name: "discussion_talkshow_5_customer_name",
    discussion_talkshow_5_watch_now: "discussion_talkshow_5_watch_now",
    discussion_talkshow_5_watch_teaser: "discussion_talkshow_5_watch_teaser",
  },
  contact: {
    contact_title: "contact_title",
    contact_share_title: "contact_share_title",
    contact_share_name: "contact_share_name",
    contact_share_phone: "contact_share_phone",
    contact_share_email: "contact_share_email",
    contact_share_message: "contact_share_message",
    contact_share_button: "contact_share_button",
    contact_share_error_message: "contact_share_error_message",
    copy_right: "copy_right",
    kusto_address: "kusto_address",
  },
  topics: {
    topic_1_header: "topic_1_header",
    topic_1_title: "topic_1_title",
    topic_1_subtitle: "topic_1_subtitle",
    topic_1_full_talkshow: "topic_1_full_talkshow",
    topic_1_full_talkshow_description: "topic_1_full_talkshow_description",
    topic_1_full_talkshow_customer: "topic_1_full_talkshow_customer",
    topic_1_full_talkshow_customer_name: "topic_1_full_talkshow_customer_name",
    topic_1_watch_now: "topic_1_watch_now",
    topic_1_short_talkshow: "topic_1_short_talkshow",
    topic_1_short_talkshow_description_1:
      "topic_1_short_talkshow_description_1",
    topic_1_short_talkshow_description_2:
      "topic_1_short_talkshow_description_2",
    topic_1_short_talkshow_description_3:
      "topic_1_short_talkshow_description_3",
    topic_1_short_talkshow_description_4:
      "topic_1_short_talkshow_description_4",
    topic_1_short_talkshow_description_5:
      "topic_1_short_talkshow_description_5",
  },
  topic2: {
    topic_2_header: "topic_2_header",
    topic_2_title: "topic_2_title",
    topic_2_subtitle: "topic_2_subtitle",
    topic_2_full_talkshow: "topic_2_full_talkshow",
    topic_2_full_talkshow_description: "topic_2_full_talkshow_description",
    topic_2_full_talkshow_customer: "topic_2_full_talkshow_customer",
    topic_2_full_talkshow_customer_name: "topic_2_full_talkshow_customer_name",
    topic_2_watch_now: "topic_2_watch_now",
    topic_2_short_talkshow: "topic_2_short_talkshow",
    topic_2_short_talkshow_description_1:
      "topic_2_short_talkshow_description_1",
    topic_2_short_talkshow_description_2:
      "topic_2_short_talkshow_description_2",
    topic_2_short_talkshow_description_3:
      "topic_2_short_talkshow_description_3",
    topic_2_short_talkshow_description_4:
      "topic_2_short_talkshow_description_4",
    topic_2_short_talkshow_description_5:
      "topic_2_short_talkshow_description_5",
  },
  topic3: {
    topic_3_header: "topic_3_header",
    topic_3_title: "topic_3_title",
    topic_3_subtitle: "topic_3_subtitle",
    topic_3_full_talkshow: "topic_3_full_talkshow",
    topic_3_full_talkshow_description: "topic_3_full_talkshow_description",
    topic_3_full_talkshow_customer: "topic_3_full_talkshow_customer",
    topic_3_full_talkshow_customer_name: "topic_3_full_talkshow_customer_name",
    topic_3_watch_now: "topic_3_watch_now",
    topic_3_short_talkshow: "topic_3_short_talkshow",
    topic_3_short_talkshow_description_1:
      "topic_3_short_talkshow_description_1",
    topic_3_short_talkshow_description_2:
      "topic_3_short_talkshow_description_2",
    topic_3_short_talkshow_description_3:
      "topic_3_short_talkshow_description_3",
    topic_3_short_talkshow_description_4:
      "topic_3_short_talkshow_description_4",
    topic_3_short_talkshow_description_5:
      "topic_3_short_talkshow_description_5",
  },
};

export const translationType = {
  introduction: "introduction",
  discussion: "discussion",
  contact: "contact",
  talkshow: "show",
  topic1: "topic1",
  topic2: "topic2",
  topic3: "topic3",
  introduce: "introduce",
};
