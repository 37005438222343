import instance from "../helpers/server";
import { api } from "../constant/index";
const state = () => ({});
const getters = {};
const mutations = {};
const actions = {
  async submitShareMessage({ commit }, payload) {
    return await instance.post(api.shareMessage, payload);
  },
  async downloadSubmission() {
    const res = await instance.get(api.downloadExcel);
    return res.data || {};
  },
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
