import instance from "../helpers/server";
import { api } from "../constant/index";
const state = () => ({
  talkshowYoutube: [],
  topic1Youtube: [],
  topic2Youtube: [],
  topic3Youtube: [],
  introduceYoutube: [],
});
const getters = {
  talkshowYoutube: (state) => state.talkshowYoutube || [],
  topic1Youtube: (state) => state.topic1Youtube || [],
  topic2Youtube: (state) => state.topic2Youtube || [],
  topic3Youtube: (state) => state.topic3Youtube || [],
  introduceYoutube: (state) => state.introduceYoutube || [],
};
const mutations = {
  setTalkShowYoutube(state, payload) {
    state.talkshowYoutube = payload;
  },
  setTopic1Youtube(state, payload) {
    state.topic1Youtube = payload;
  },
  setTopic2Youtube(state, payload) {
    state.topic2Youtube = payload;
  },
  setTopic3Youtube(state, payload) {
    state.topic3Youtube = payload;
  },
  setIntroduceYoutube(state, payload) {
    state.introduceYoutube = payload;
  },
};
const actions = {
  async getTalkshowYoutube({ commit }, payload) {
    const res = await instance.get(api.talkShowYoutube, {
      params: {
        type: payload,
      },
    });
    commit("setTalkShowYoutube", res?.data);
    return res?.data || [];
  },
  async getTopic1Youtube({ commit }, payload) {
    const res = await instance.get(api.talkShowYoutube, {
      params: {
        type: payload,
      },
    });
    commit("setTopic1Youtube", res?.data);
    return res?.data || [];
  },

  async getTopic2Youtube({ commit }, payload) {
    const res = await instance.get(api.talkShowYoutube, {
      params: {
        type: payload,
      },
    });
    commit("setTopic2Youtube", res?.data);
    return res?.data || [];
  },

  async getTopic3Youtube({ commit }, payload) {
    const res = await instance.get(api.talkShowYoutube, {
      params: {
        type: payload,
      },
    });
    commit("setTopic3Youtube", res?.data);
    return res?.data || [];
  },

  async getIntroduceYoutube({ commit }, payload) {
    const res = await instance.get(api.talkShowYoutube, {
      params: {
        type: payload,
      },
    });
    commit("setIntroduceYoutube", res?.data);
    return res?.data || [];
  },

  async updateTalkShow({ commit }, payload) {
    return await instance.patch(api.talkShowYoutube, payload);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
